import { $win, $body, $document } from '../utils/globals'

//  Nav mobile
$document.on('click', '.nav-trigger', function(e){
    e.preventDefault();

    $(this).toggleClass('open');
    $body.toggleClass('show-nav');
});

$document.on('click', '.nav .menu-item-has-children > a', function(e){
	const $this = $(this);
	const $parent = $this.parent();

	if ( !$parent.hasClass('is-expanded') && $win.width() < 1024 ) {
		e.preventDefault();

		$this
			.next()
				.slideDown()
			.parent()
				.addClass('is-expanded')
			.siblings()
				.removeClass('is-expanded')
				.find('.sub-menu')
					.slideUp();
	}
});
