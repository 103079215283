/**
 * Filter functionality
 */
import $ from 'jquery';
import 'selectric';

const $filter = $('.js-filter');
const $form = $('.js-form-filters');

$filter.selectric({
	disableOnMobile: false, 
	nativeOnMobile: false
});

$filter.on('change', function () {
	$form.submit();
});
