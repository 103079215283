/**
	Google Map
*/

function initializeGoogleMaps() {
	$('.map .map__inner').each( (i, element) => {
		const $element = $(element);
		const lat = $element.data('lat');
		const lng = $element.data('lng');
		const center = new google.maps.LatLng( $element.data('lat'), $element.data('lng') );

		if (!lat && !lng) {
			return;
		}

		const styles = [{"featureType": "all", "elementType": "all", "stylers": [{"hue": "#008eff"} ] }, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"} ] }, {"featureType": "road", "elementType": "all", "stylers": [{"saturation": "0"}, {"lightness": "0"} ] }, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"} ] }, {"featureType": "water", "elementType": "all", "stylers": [{"visibility": "simplified"}, {"saturation": "-60"}, {"lightness": "-20"} ] } ]

		const mapOptions = {
			zoom: 16,
			center,
			disableDefaultUI: true,
			backgroundColor: '#ffffff',
		panControl: false,
			zoomControl: true,
			scrollwheel: true,
			styles,
		};

		const map = new google.maps.Map(document.getElementById( $element.attr('id') ),mapOptions);

		const position = new google.maps.LatLng( parseFloat(lat), parseFloat(lng));

		const marker = new google.maps.Marker({
			map,
			position
		});
	});
}

if ( $('.map').length > 0 ) {
	google.maps.event.addDomListener(window, 'load', initializeGoogleMaps);
}
