import { $body, $document } from '../utils/globals';
import 'magnific-popup';

// $('.js-popup-video').magnificPopup({
// 	type: 'inline',
// 	callbacks: {
// 		open: function() {
// 			// $body.removeClass('before-open-popup');

// 	    	$(this.content).find('video')[0].play();
// 	    },
// 	  	close: function() {
// 	    	$(this.content).find('video')[0].load();
// 	    }
// 	}
// });

$document.on('click', '.js-popup-video', function(e){
	e.preventDefault();

	let iframeSrc = $(this).attr('href');

	$body.addClass('before-open-popup');

  	setTimeout(function() {
		$.magnificPopup.open({
			items: {
				src: iframeSrc,
			},
			type: 'inline',
			removalDelay: 500,
			midClick: true,
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'with-fade';
				},
				open: function() {
			    	$(this.content).find('video')[0].play();
			    },
			  	close: function() {
			    	$(this.content).find('video')[0].load();
			    	setTimeout(function() {
			    		$body.removeClass('before-open-popup');
			    	}, 500);
			    }
			}
		});
  	}, 1000);
});

$document.on('click', '.js-popup-iframe', function(e){
	e.preventDefault();

	let iframeSrc = $(this).attr('href');

	$body.addClass('before-open-popup');

  	setTimeout(function() {
		$.magnificPopup.open({
			items: {
				src: iframeSrc,
			},
			type: 'iframe',
			removalDelay: 500,
			midClick: true,
			iframe: {
				markup:
	            		'<button title="Close (Esc)" type="button" class="mfp-close">×</button>'+
						'<div class="mfp-iframe-scaler">'+
		            		'<iframe class="mfp-iframe" frameborder="0" allowfullscreen allow="autoplay"></iframe>'+
		            	'</div>',
				patterns: {
					youtube: {
					    src: 'https://www.youtube.com/embed/%id%?&autoplay=1' // URL that will be set as a source for iframe.
					  },
					vimeo: {
						src: 'https://player.vimeo.com/video/%id%?playsinline=1&autoplay=1&byline=0&autopause=0'
					},
				},
			},
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'with-fade';
				},
			  	close: function() {
			    	setTimeout(function() {
			    		$body.removeClass('before-open-popup');
			    	}, 500);
			    }
			}
		});
  	}, 1000);
});
