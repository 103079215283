import { $document, $win } from '../utils/globals';

if ($('.section-blog').length) {
	$document.on('click', '.filters__lists a, .paging a', function(e) {
		e.preventDefault();

		var $ajaxContainer = $('.section-blog');
		var url = $(this).attr('href');

		$.ajax({
			url: url,
			method: 'GET',
			beforeSend() {
				$ajaxContainer.addClass('ajax-loading');
			},
			success: function( response ) {
				$ajaxContainer.html( $(response).find('.section-blog > *') );
				$ajaxContainer.removeClass('ajax-loading');

				window.history.pushState({}, $(response).filter('title').text(), url);
				document.title = $(response).filter('title').text();
			},
			error() {
				$ajaxContainer.html('<h3>Error has occurred please try again!</h3>');
				$ajaxContainer.removeClass('ajax-loading');
			},
			always() {
				$ajaxContainer.removeClass('ajax-loading');
			}
		});
	});
}

$document.ready(function() {
	$win.on('popstate', function(e) {
		location.reload();
	});
});
