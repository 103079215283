/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/magnific-popup';

import './modules/body-classes';

import './modules/google-maps';

import './modules/navigation';

import './modules/scroll-magic';

import './modules/load-more-posts';

import './modules/scroll-to-section';

import './modules/filter';
