import 'animation.gsap';
import 'debug.addIndicators';
import TweenMax from 'TweenMax';
import ScrollMagic from 'ScrollMagic';
import { $win } from '../utils/globals';
const winHeight = $win.height();

$win.on('load', () => {
	initParallaxAnimation();
})

function initParallaxAnimation() {
	// init controller
	const controller = new ScrollMagic.Controller();

	// create a scene for Section Bg
	$('.js-section-bg').each(function(){
		const $this = $(this);
		const bgColor = $this.data('background');

		const scene = new ScrollMagic.Scene({
			triggerElement: $this[0],
			triggerHook: .7,
		})
			.setTween('.section-bg', 1, {
				backgroundColor: bgColor
			})
				.addTo(controller)
				// .addIndicators({name: $this[0].classList});
	})

	// create a scene for Fade Into View
	$('.js-animate-translate > *').each((i, child) => {
		const parent = child.parentNode;
		const tween = parent.dataset.tween;
		let settings = {};

		if (parent.dataset.parallax) {
			settings = JSON.parse(parent.dataset.parallax.replace(/\'/g, '"'));
		}

		const tweens = {
			fade: [
				child, 1,
				{ opacity: 0, immediateRender:false },
				{ opacity: 1 },
			],
			fadeup: [
				child, 1,
				{ y: winHeight / 2, opacity: 0, immediateRender:false },
				{ y: 0, opacity: 1 }
			]
		};

		const scene = new ScrollMagic.Scene({
				triggerElement: parent,
				triggerHook: .8,
				duration: winHeight / 2,
				... settings
			})
			.setTween(TweenMax.fromTo( ... tweens[tween]))
				.addTo(controller)
				// .addIndicators({name: child.classList});


				updateSceneOffset(scene, settings.offset)
				$win.on('resize', () => updateSceneOffset(scene, settings.offset));

				setTimeout(function() {
					scene.update(true);
				}, 2000);
	});

	// Reset offset on mobile

	let wait = null;

	function updateSceneOffset(scene, offset = 0){
		clearTimeout(wait);

		wait = setTimeout(function() {
			if ($win.width() < 768) {
				scene.offset(0);
			} else {
				scene.offset(offset);
			}
		}, 500);
	};

	// Team Members

	$('.team-members').each((i, container) => {
		const $members = $(container).find('.team-member');

		$members.each((i, member) => {
			const parent = member.parentNode;
			let pass = i == 1 || i == 5;

			// Pass the first scene
			if (pass) return;

			const y = i == 0 ? 120 : i == 2 ? -120 : i == 3 ? -240 : i == 4 ? -120 : 0;

			const sceneEnter = new ScrollMagic.Scene({
					triggerElement: parent,
					triggerHook: .8,
					duration: winHeight * .4,
				})
				.setTween(member, { y })
				.addTo(controller)
				// .addIndicators({name: member.classList});

			pass = i > 2;

			// Pass the second scene
			if (pass) return;

			const y2 = i == 0 ? -120 : i == 2 ? 120 : 0;

			const sceneLeave = new ScrollMagic.Scene({
					triggerElement: parent,
					triggerHook: .2,
					duration: winHeight * .4,
				})
				.setTween(member, { y: y2 })
				.addTo(controller)
				// .addIndicators({name: member.classList});
		});
	});

	// Circle

	$('.circle .circle__animated').each((i, container) => {
		$(container).find('img').each((i, image) => {
			const rotation = image.dataset.rotation;

			const scene = new ScrollMagic.Scene({
					triggerElement: container,
					triggerHook: .8,
					duration: winHeight,
					offset: i * winHeight * .1
				})
				.setTween(image, { rotation })
				.addTo(controller)
				// .addIndicators();
		});
	});
}
